<template>
  <div>
    <!-- <Sidebar /> -->
    <div class="mt-7 pt-7 ps-2">
      <HeaderPage />
      <!-- <UserName /> -->
    </div>
    <div style="margin-bottom: 5em">
      <!-- <HeaderNavigasi /> -->

      <v-card
        style="
          padding: 0px;
          margin: 0px;
          background-color: #e1dddd;
          border-radius: 0px;
        "
        flat
      >
        <div class="ps-2">
          <p class="py-2 d-flex align-center">
            <span
              class="pe-3"
              style="
                height: 30px;
                display: inline-block;
                border-left: 8px solid #ff0090;
              "
            ></span>
            {{ nameParent.category_name }}
          </p>
        </div>
      </v-card>
      <v-container>
        <div v-if="isLoading" class="mt-">
          <v-row>
            <v-col class="pb-1" cols="4" lg="2" md="2" sm="2">
              <v-skeleton-loader
                v-bind="attrs"
                type="image"
              ></v-skeleton-loader>
            </v-col>
            <v-col class="font-weight-medium pb-0">
              <v-skeleton-loader
                v-bind="attrs"
                type="image"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row style="overflow-x: auto; flex-wrap: nowrap" class="row-scroll">
            <v-col
              v-for="i in 10"
              cols="4"
              md="2"
              lg="2"
              sm="2"
              :key="i"
              class="parent-style-product pe-0"
            >
              <Skeleton />
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row
            v-if="itemsOfParent.length > 0"
            style="padding-right: 0px"
            justify="space-between"
          >
            <v-col
              class="font-weight-medium pb-0"
              style="
                font-size: 16px;
                margin-bottom: 1em;
                padding-right: 0px;
                padding-bottom: 1em;
                padding-left: 5px;
              "
            >
              <div>
                <p class="ps-3 text_title">{{ nameParent.category_name }}</p>
              </div>
            </v-col>
            <v-col style="padding-right: 0px" cols="4" lg="2" md="2" sm="2">
              <div
                class=""
                style="font-size: 15px; font-weight: 800"
                @click="$router.push(`/items/category/c/${slug_category}/1`)"
              >
                {{ $t("message.view-all") }}
              </div>
            </v-col>
          </v-row>

          <div v-if="itemsOfParent.length > 0">
            <v-row
              style="overflow-x: auto; flex-wrap: nowrap; padding-right: 0px"
              class="row-scroll"
            >
              <v-col
                v-for="(item, idx) in itemsOfParent"
                cols="4"
                md="3"
                lg="2"
                sm="3"
                :key="idx"
                class="parent-style-product"
                style="padding-right: 0px"
              >
                <div>
                  <Product
                    :data="item"
                    @data-slug="getModal"
                    :loading="isLoading"
                  />
                </div>
              </v-col>
            </v-row>
          </div>
          <!-- TEMPORARILY HIDE -->
          <!-- <div v-else>
            <p class="font-weight-bold mt-4">
              {{ $t("message.no-product") }}
            </p>
          </div> -->
          <DetailModal
            :item_slug="slug"
            :showDialog="dialog"
            @get-modal="handleModal"
            @dialogVisible="dialog"
          />

          <ConfirmationProduct
            :data_product="data_dialog_confirm"
            :showDialog="dialog_confirmation_product"
            @close="getCloseModal"
            @dialogVisible="dialog_confirmation_product"
          />

          <Warning
            :openDialog="dialog_warning"
            @handle-dialog="handleDialog_warning"
          />
        </div>

        <div class="pt-7" v-for="(child, i) in categoryWithChild" :key="i">
          <div>
            <ProductSlide
              :data_slide="child.items"
              :title_slide="child.category_name"
              :link_slide="
                child.is_child > 0
                  ? `/items/category/${child.slug}`
                  : `/items/category/c/${child.slug}/1`
              "
              :data_store="[]"
              :is_loading="isLoadingChild"
              :slug_category="slug_category"
            />
          </div>
        </div>
      </v-container>
      <CategoryFooter />
    </div>
    <Cashier class="" />
    <Sidebar />
  </div>
</template>

<script>
import Product from "../components/Product.vue";
import Sidebar from "../components/developmentv2/Sidebar.vue";
import User from "../components/User.vue";
import HeaderNavigasi from "../components/HeaderNavigasi.vue";
import Cashier from "../components/Cashier.vue";
import ProductSlide from "@/components/ProductSlide";
import CategoryFooter from "../components/CategoryFooter.vue";
import Skeleton from "../components/skeleton/Skeleton.vue";
import HeaderPage from "../components/developmentv2/HeaderPage.vue";
import { mdiChevronRight } from "@mdi/js";
import DetailModal from "../components/DetailModal.vue";
import ConfirmationProduct from '../components/ConfirmationProduct.vue';
import Warning from '../components/Warning.vue'

export default {
  name: "DailyNecessity",
  components: {
    Sidebar,
    UserName: User,
    HeaderNavigasi,
    Cashier,
    ProductSlide,
    CategoryFooter,
    Product,
    Skeleton,
    HeaderPage,
    DetailModal,
    ConfirmationProduct,
    Warning
  },
  data() {
    return {
      iconViewAll: mdiChevronRight,
      slug_category: this.$router.history.current.params.slug,
      nameParent: "",
      isLoading: true,
      isLoadingChild: true,
      attrs: {
        height: 30,
        class: "mb-1",
        boilerplate: true,
        elevation: 0,
      },
      slug: "",
      dialog: false,
      dialog_confirmation_product: false,
      data_dialog_confirm: {},
      data_dialog_warning: null,
      dialog_warning: false,
    };
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  computed: {
    categoryWithChild() {
      this.nameParent = this.$store.state.itemList_module.categoryWithChild;
      return this.$store.state.itemList_module.categoryWithChild.child;
    },
    itemsOfParent() {
      return this.$store.state.itemList_module.itemsOfParentCategory;
    },
  },
  created() {
    this.dispatchDaily();
  },
  methods: {
    handleDialog_warning(val) {
      if (val.selected == "yes") {
        this.message.title = this.$t("message.title-add-carts");
        this.$store.dispatch("carts_module/addCarts", {
          itemId: this.data_dialog_warning.id,
          storeId: this.data_dialog_warning.store_id,
          message: {
            title: this.message.title,
          },
        });
        setTimeout(() => {
          this.dialog = false;
        }, 1800);
      }
      this.dialog_warning = false;
    },
    getCloseModal() {
      this.dialog = false;
      this.dialog_confirmation_product = false;
    },
    handleModal(data) {
      if (data[0] == "ConfirmProduct") {
        this.data_dialog_confirm = data[1];
        this.dialog_confirmation_product = true;
      } else if (data[0] == "dialog_alcohol") {
        this.data_dialog_warning = data[1];
        this.dialog_warning = true;
      } else {
        this.dialog = false;
      }
    },
    getModal(data) {
      if (typeof data === "string") {
        this.slug = data;
        this.dialog = true;
      } else {
        this.data_dialog_confirm = data;
        this.dialog_confirmation_product = true;
      }
    },
    async dispatchDaily() {
      this.isLoading = true;
      this.isLoadingChild = true;
      let idParams = this.$router.history.current.params.slug;
      let data = await this.$store.dispatch("itemList_module/dailyNecessity", {
        slug: idParams,
      });
      this.isLoadingChild = false;
      this.isLoading = false;
      return data;
    },
  },
};
</script>

<style></style>
